import { S3 } from "aws-sdk"
import { isAuthenticated } from "./auth"

const Bucket = 'design-system-dependency-report'
const Prefix = 'reports/report-'
const Ext = '.json'

let s3 = null;
const getS3 = () => {
  if (!s3 && isAuthenticated()) {
    s3 = new S3({ region: 'us-east-1' })
  }
  return s3;
}

export const listReports = async () => {
  const response = await getS3().listObjectsV2({ Bucket, Prefix }).promise()
  return response.Contents
    .sort()
    .reverse()
    .map(({ Key }) => new RegExp(`${Prefix}(.*)${Ext}`).exec(Key)[1])
}

export const getReport = async reportDate => {
  if (!reportDate) return null
  const Key = Prefix + reportDate + Ext
  const response = await getS3().getObject({ Bucket, Key }).promise()
  return JSON.parse(response.Body)
}

export const getAllReports = async () => {
  return Promise.all((await listReports()).map(getReport))
}