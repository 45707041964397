export const PROJECT_NAMES_MAP = {
  go: "Eco",
  PackManager: "PM",
  QCloud: "QC",
  "production-scheduling": "PS",
  "Common-Platform-Interface": "CPI",
  cn_edi: "B2BEDI",
  "eco-invoices": "Eco Inv."
}

export const friendlyAppName = (project = "go") => {
  return PROJECT_NAMES_MAP[project] || project
}
