export const getAllComponents = report => {
  const allComponents = report.nds.exports
  return allComponents
}

export const getAllProjects = report => {
  return report.projects.map(({ project }) => project)
}

export const getReportByDate = (reports, date) => {
  const isSameDate = report => report.date === date
  return reports.find(isSameDate)
}

export const getUsageByComponent = (report, componentName) => {
  let projectToFilesMap = {}
  report.forEach(({ project, components }) => {
    components.forEach(({ files, name }) => {
      if (name === componentName) {
        projectToFilesMap[project] = files
      }
    })
  })
  const totalUsage = Object.keys(projectToFilesMap).reduce(
    (total, project) => total + projectToFilesMap[project],
    0
  )
  return totalUsage
}

const getReportByProject = (projects, projectName) => {
  return projects.find(({ project }) => project === projectName)
}

export const getUsageByProject = (report, projectName) => {
  const projectReport = getReportByProject(
    report.projects,
    projectName
  );
  if (projectReport) {
    const total = projectReport.components.reduce((total, component) => total + component.files, 0)
    return total
  }
  return 0
}

export const getFilePathsByComponentName = (report, projectName, compName) => {
  const currentProject = report.projects.filter(
    ({ project }) => project === projectName
  )[0]
  const currentComponent = currentProject.components.filter(
    ({ name }) => name === compName
  )[0]
  const currentFilePaths = currentComponent.filepaths
  return currentFilePaths
}

export const getVersionDataByProject = (projects, projectName) => {
  return getReportByProject(projects, projectName).versions
}
